import { Field } from "./field.model";
import { Squad } from "./squad.model";
import { IStats, UbikoStats } from "./ubiko-stats.model";

export class Session {

    public static STARTED = 'started';
    public static COMPUTED = 'computed';
    public static FINISHED = 'finished';
    public static TYPE_MATCH = 'match';
    public static TYPE_MATCH_TRAINING = 'match_training';
    public static TYPE_TRAINING = 'training';

    id: string;
    name: string; 
    created: string;  
    finished: string;
    status: string;
    squad: Squad;
    score?: Score;
    params?: any;
    typeInfo: TypeInfo;
    training_type: string;
    field: Field;
    ubiko_stats: UbikoStats;
    parts?: Part[];
    rpe?: SessionRPE;
    kit: SessionKit;
    _devices: any;
    source: string;

    constructor() {}      
}

export interface Part {
    id: string;
    name: string; 
    start: string;
    end: string;
    status: string;
    ubiko_stats: IStats;
    _players: PartPlayer[];
    players: any;
    stats: IStats;   
    events: any[];
}

export interface PartPlayer {
    id: string;
    ubiko_stats: IStats;    
    start: string;
    end: string;
    device?: number;
    data: {
        heatmap?: string;
    }
    clone?: number;
}

export interface SessionRPE {
    rpe?: number;
}

export interface TypeInfo {
    name: string;
    type: string;
}

export interface SessionKit {
    id: string;
    type: string;
}

export interface Score {
    goals: number;
    opponent_goals: number;
}

export function completeSession(session: Session, completeParts?: boolean) {
    if(session.ubiko_stats) {
        session.ubiko_stats._players = [];
        if(session.ubiko_stats.players) {
            for (let [key, value] of Object.entries(session.ubiko_stats.players)) {        
                let stats = value as IStats;
                stats.id_player = key;
                session.ubiko_stats._players.push(stats);
            }
        }
    }
    //session._parts = [];
    if(completeParts && session.parts) {        
        session.parts.forEach(part => {   
            part._players = [];
            for (let value of Object.values(part.players)) {  
                part._players.push(value as PartPlayer);
            }
        });
    }
}


