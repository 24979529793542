import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

    constructor(private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        /*console.log('method: ' + req.method);
        if(req.method == 'OPTIONS') {
            console.log('http blocked');
            return EMPTY;
        } 

        const userData = this.authService.getAuthFromLocalStorage();
        let idToken = '';
     
        if(userData) {
            idToken = userData.idToken;
        }
  
        const clonedRequest = req.clone({
            headers: headers,
            withCredentials: true
        });

        req.headers.append('Content-Type', 'application/json');
        req.headers.append('Access-Control-Allow-Origin', '*');
        req.headers.append('Authorizationv', 'Bearer ' + idToken);*/

        return next.handle(req).pipe( 
            tap(() => {
            },            
            (err: any) => {                
                console.log(err);
                if(err instanceof HttpErrorResponse) {
                    if(err.status != 401) {
                        return;
                    }
                    //this.router.navigateByUrl(/login);
                    this.authService.logout();
                }
            }));
    }    
}