import { TechSync } from './device-sync.model';
import { SquadDenormalized } from './squad.model';
import { TeamDenormalized } from './team.model';

export class Tech {    
    id: string;    
    name: string;      
    squads?: any;
    teams?: any;
    _squads?: SquadDenormalized[];   
    _teams?: TeamDenormalized[];
    syncs?: TechSync[];
    send_invitation?: boolean;
    socialmedia?: any;
    user?: any;
    _deleted?: boolean;
    photo_url?: string;
    phone?: string;
}

export function completeTech(tech: Tech) {
    tech._squads = [];
    tech._teams = [];
    if(tech.squads) {        
        for (let value of Object.values(tech.squads)) {   
            tech._squads.push(value as SquadDenormalized);
        }
    }
    if(tech.teams) {        
        for (let value of Object.values(tech.teams)) {   
            tech._teams.push(value as TeamDenormalized);
        }
    }
}