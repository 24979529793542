// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  test: false,
  desa: true,
  firebase: {
    apiKey: "AIzaSyBK_s7rghkIMSFWSBoh0zzAySym7jGgZ6k",
    authDomain: "ubiko-desa.firebaseapp.com",
    databaseURL: "https://ubiko-desa-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "ubiko-desa",
    storageBucket: "ubiko-desa.appspot.com",
    messagingSenderId: "735955647213",
    appId: "1:735955647213:web:9ac130ae182f15449f6ae4",
    measurementId: "G-P8VNKX698D"
  },  
  appVersion: 'v1.0.0',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'https://europe-west1-ubiko-desa.cloudfunctions.net/app2/api/',
  realtimeUrl: '',
  appThemeName: 'Metronic',
  appPurchaseUrl: 'https://1.envato.market/EA4JP',
  appHTMLIntegration: 'https://preview.keenthemes.com/metronic8/demo1/documentation/base/helpers/flex-layouts.html',
  appPreviewUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1/',
  appPreviewAngularUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1',
  appPreviewDocsUrl: 'https://preview.keenthemes.com/metronic8/angular/docs',
  appPreviewChangelogUrl: 'https://preview.keenthemes.com/metronic8/angular/docs/changelog'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
